import { Box, Button, useTheme } from "@mui/material";
import {
  ContentBox,
  PimoTableEventNames,
  PimoTableEventPayload,
  Tooltip,
} from "@pimo/pimo-components";
import { LockDetail } from "@pimo/resource-lock-plugin";
import { InitiativeFilterData } from "in-types";
import { IN_COLORS } from "in-utils";
import React, { PropsWithChildren } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { STRAPI_URL } from "../../app/env";
import { InitiativesStatusTableFilter } from "./initiatives-status-table-filter";

export type StatusReportTableContainerProps = PropsWithChildren<{
  title: string;
  lockDetail?: LockDetail & { ownEmail?: string };
  lockInitiativesForOE: () => void;
  addInitiativePath: string;
  editInitiativePath: string;
  filterData: InitiativeFilterData;
  fireEvent?: (
    eventName: PimoTableEventNames,
    payload: PimoTableEventPayload
  ) => void;
}>;

export const InitiativesStatusTableContainer = React.forwardRef(
  (
    {
      children,
      title,
      lockDetail,
      lockInitiativesForOE,
      editInitiativePath,
      addInitiativePath,
      filterData,
      fireEvent,
    }: StatusReportTableContainerProps,
    ref
  ) => {
    const navigate = useNavigate();
    const params = useParams<"oeId">();
    const theme = useTheme();

    const isLockedByOtherUser =
      lockDetail?.isLocked === true &&
      !!lockDetail.lockedBy &&
      !!lockDetail.ownEmail &&
      lockDetail.lockedBy !== lockDetail.ownEmail;

    return (
      <ContentBox
        ref={ref}
        icon={
          <Box
            component="img"
            src="checklist.svg"
            sx={{
              backgroundColor: "white",
              borderRadius: "12px",
              p: "5px",
              height: "36px",
            }}
          />
        }
        title={title}
        statusBar={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              disabled={false}
              sx={{
                border: "1px solid",
                padding: "4px 16px",
                color: IN_COLORS.white,
                borderCollapse: theme.palette.primary.dark,
                borderRadius: "4px",
              }}
              onClick={() =>
                window.open(
                  [STRAPI_URL, "api/export", params.oeId]
                    .filter(Boolean)
                    .join("/")
                )
              }
            >
              Download Data
            </Button>
            <Tooltip
              title={
                isLockedByOtherUser
                  ? `Adding initiatives is currently locked by "${lockDetail?.lockedBy ?? "Unknown"}".`
                  : ""
              }
            >
              <span>
                <Button
                  disabled={isLockedByOtherUser}
                  sx={{
                    borderRadius: "4px",
                    background: IN_COLORS.white,
                    marginLeft: "12px",
                    "&:hover": {
                      background: IN_COLORS.white,
                    },
                  }}
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    lockInitiativesForOE();

                    return navigate(addInitiativePath);
                  }}
                >
                  Add Initiative {isLockedByOtherUser && "🔒"}
                </Button>
              </span>
            </Tooltip>
            <Tooltip
              title={
                isLockedByOtherUser
                  ? `Editing initiatives is currently locked by "${lockDetail.lockedBy ?? "Unknown"}"`
                  : ""
              }
            >
              <span>
                <Button
                  disabled={isLockedByOtherUser}
                  sx={{
                    borderRadius: "4px",
                    background: IN_COLORS.white,
                    marginLeft: "12px",
                    "&:hover": {
                      background: IN_COLORS.white,
                    },
                  }}
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    lockInitiativesForOE();

                    return navigate(editInitiativePath);
                  }}
                >
                  Edit {isLockedByOtherUser && "🔒"}
                </Button>
              </span>
            </Tooltip>
            <InitiativesStatusTableFilter
              fireEvent={fireEvent}
              filterData={filterData}
            />
          </Box>
        }
      >
        <Box sx={{ overflowY: "auto", width: "100%" }}>{children}</Box>
      </ContentBox>
    );
  }
);

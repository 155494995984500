import { NotificationsPlugin } from "@pimo/notifications-plugin";
import { App } from "@pimo/pimo-app-builder";
import { DefaultOverlayNotificationPayload } from "@pimo/pimo-components";
import { generatePath } from "react-router-dom";

import {
  InOverlay,
  InOverlayEventNames,
  InOverlayEventPayload,
  InOverlayProps,
} from "../components/overlay/in-overlay.tsx";
import { InAppState } from "./app.tsx";
import { APP_ROUTES } from "./constants.ts";
import { fetchLogs } from "./helpers/fetch-logs.ts";
import { isUserAdmin } from "./helpers/is-user-admin.ts";

export const createOverlay = (app: App<InAppState>) => {
  const view = app.createOverlayView({ name: "overlay" });
  const overlay = view.addComponent<
    InOverlayProps,
    InOverlayEventNames,
    InOverlayEventPayload
  >({ component: InOverlay });

  overlay.on("overlay:show-logs", async () => {
    const logs = await fetchLogs();

    app.patchAppState({ logs });
  });
  overlay.on("overlay:settings-click", () => {
    app.navigate(APP_ROUTES.settingsNotifications);
    app.patchAppState({ isSidebarOpen: false });
  });
  overlay.on("overlay:click-logo", () => app.navigate("/dashboard"));
  overlay.on("overlay:notification-read", (params) => {
    const notificationsPlugin = app.getPluginByName<
      NotificationsPlugin<InAppState>
    >("NotificationsPlugin");

    void notificationsPlugin?.handleOverlayNotificationReadEvent(
      params as { payload: DefaultOverlayNotificationPayload }
    );
  });
  overlay.mapState(
    ({
      isSidebarOpen,
      userProfile,
      operatingEntities,
      program,
      logs,
      banner,
    }) => ({
      header: {
        logo: { big: "allianz.svg", small: "allianz-small.svg" },
        username: userProfile?.email ?? "",
        enableLogs: true,
        onLogsShow: () => {
          overlay.fireEvent("overlay:show-logs");
        },
      },
      notifications: {
        banner: banner,
      },
      isSidebarOpen,
      logs: logs ?? [],
      menuEntries: [
        isUserAdmin(userProfile)
          ? {
              title: "Group Dashboard",
              link: `${APP_ROUTES.dashboard}`,
              level: 0,
              active: window.location.pathname.includes(
                `${APP_ROUTES.dashboard}`
              ),
              icon: "dashboard.svg",
            }
          : undefined,
        {
          title: "OE Overview",
          link: `${APP_ROUTES.overview}`,
          level: 0,
          active: window.location.pathname.includes(`${APP_ROUTES.overview}`),
          icon: "clipboard.svg",
        },
        {
          title: "OE Initiatives",
          level: 0,
          items: operatingEntities?.map((oe) => {
            return {
              level: 1,
              link: generatePath(APP_ROUTES.operatingEntities, {
                oeId: String(oe.id) ?? "",
                viewname: "dashboard",
              }),
              title: oe.operatingEntity ?? "",
              active:
                window.location.pathname.includes(
                  `operating-entities/${oe.id ?? ""}/`
                ) ||
                window.location.pathname ===
                  `operating-entities/${oe.id ?? ""}`,
            };
          }),
          active: window.location.pathname.includes("operating-entities"),
          icon: "barchart.svg",
        },
      ].filter(Boolean) as InOverlayProps["menuEntries"],
      reportingDate: program?.reportingDate,
      userProfile: userProfile,
    })
  );

  overlay.on("overlay:toggle-sidebar", () => {
    app.patchAppState({
      isSidebarOpen: !app.getAppState().isSidebarOpen,
    });
  });

  return overlay;
};

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import { Card, CardContent, type CardProps } from "../../molecules";

export interface DonutChartCardProps {
  /** Global Title of the Card, displayed to the left */
  title: string;
  /** Value of series */
  series: number[];
  /** Chart Title of the Graphic, displayed to the bottom */
  chartTitle?: string;
  /** Chart's colors */
  backgroundColor: string[];
  legendIcons?: string[];
  /** Chart's colors */
  labels: readonly string[];
  /**
   * colors schemes for the label badges, if this property is passed
   * the legend on the side will render badges instead of simple text
   */
  labelBadgesColors?: { chipColor: string; textColor: string }[];
  /** Chart's tooltips Labels */
  toolTipLabels?: readonly string[];
  toolTipEnabled?: boolean;
  /** show labels inside chart */
  showLabels?: boolean;
  /** enable hover effects over chart */
  enableHover?: boolean;
  /** callback function triggered on a donut section click */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSectionClickHandler?: (event: any, chartContext: any, config: any) => void;
  /** (optional) more styling props */
  cardProps?: CardProps;
}

export const DonutChartCard: PimoReactComponent<DonutChartCardProps> = ({
  title,
  series,
  backgroundColor,
  labels,
  toolTipLabels = [],
  showLabels,
  toolTipEnabled,
  enableHover,
  cardProps = {},
  labelBadgesColors,
  legendIcons,
  onSectionClickHandler,
}) => {
  const theme = useTheme();
  const options: ApexOptions = {
    labels: toolTipLabels as unknown as string[],
    colors: backgroundColor,
    legend: { show: false },
    chart: {
      animations: {
        enabled: false,
      },
    },
    dataLabels: { enabled: false },
    tooltip: {
      enabled: toolTipEnabled,
      fillSeriesColor: false,
      theme: "light",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
          labels: {
            show: showLabels,
            name: {
              offsetY: 10,
            },
            total: {
              show: true,
              showAlways: true,
            },
            value: {
              show: false,
            },
          },
        },
      },
    },
  };

  if (!enableHover) {
    options.states = {
      hover: {
        filter: {
          type: "none",
        },
      },
    };
  }

  if (onSectionClickHandler) {
    options.chart = {
      ...(options.chart ?? {}),
      events: {
        dataPointSelection: onSectionClickHandler,
        dataPointMouseEnter: (event: MouseEvent) => {
          (event?.target as HTMLInputElement).style.cursor = "pointer";
        },
      },
    };
  }

  return (
    <Card {...cardProps} data-testid="DonutChart" title={title}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: { xs: "column", xl: "row" },
          gap: {
            xs: 2,
            xl: 0,
          },
          pt: 6,
          height: useMediaQuery(theme.breakpoints.down("xl")) ? "100%" : 350,
          alignItems: "center",
          "@media print": {
            pt: 2,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            minWidth: {
              xs: "calc(100% - 80px)",
              xl: labelBadgesColors ? 150 : "200px",
            },
            "@media print": {},
          }}
        >
          {labels.map((label, index) =>
            labelBadgesColors ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                }}
                key={index}
              >
                <Box
                  sx={{
                    lineHeight: "1.5rem",
                    fontSize: "12px",
                    height: "1.5rem",
                    textAlign: "center",
                    fontWeight: 500,
                    flex: "1 1 100%",
                    borderRadius: 12,
                    maxWidth: 90,
                    backgroundColor: labelBadgesColors[index].chipColor,
                    color: labelBadgesColors[index].textColor,
                  }}
                >
                  {label}
                </Box>
                <Typography variant={"subtitle2"}>{series[index]}</Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                  maxWidth: 180,
                }}
                key={index}
              >
                {legendIcons?.[index] ? (
                  <Box
                    component={"img"}
                    src={legendIcons[index]}
                    sx={{ marginLeft: -0.5 }}
                  />
                ) : (
                  <Box
                    fontSize="small"
                    sx={{
                      borderRadius: "100%",
                      height: "15px",
                      width: "15px",
                      flex: "0 0 15px",

                      background: backgroundColor[index],
                      border:
                        backgroundColor[index]?.toUpperCase() === "#FFFFFF"
                          ? "#003781 1px solid"
                          : "",
                    }}
                  />
                )}

                <Typography
                  component="span"
                  sx={{ flex: "1 1 100%", maxWidth: 130 }}
                >
                  {label}
                </Typography>
                <Typography variant={"subtitle2"}>{series[index]}</Typography>
              </Box>
            )
          )}
        </Box>

        <Box
          sx={{
            flex: "1 1 100%",
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
            "@media print": {
              display: "none",
            },
          }}
        >
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            className="apex-charts"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default DonutChartCard;

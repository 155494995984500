import { FormControl, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";

import type {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
  FormFieldValidationDefinition,
} from "../../form-field";
import { MULTI_TEXT_FORM_FIELD_TYPE } from "../types";
import { MultiTextFormFieldControlledComponent } from "./multi-text-form-field-controlled-component";

export type MultiTextFormFieldDefinition = BaseFormFieldDefinition<
  typeof MULTI_TEXT_FORM_FIELD_TYPE
> & {
  type: typeof MULTI_TEXT_FORM_FIELD_TYPE;
  min?: FormFieldValidationDefinition<number>;
  max?: FormFieldValidationDefinition<number>;
  items?: { label: string; value: string }[];
  showMoreIndicator?: boolean;
};

export function MultiTextFormField({
  disabled,
  errorMessage,
  max,
  min,
  name,
  required,
  items,
  showMoreIndicator,
}: MultiTextFormFieldDefinition & AdditionalFormFieldProps) {
  return (
    <FormControl error={!!errorMessage} fullWidth>
      <Controller
        name={name}
        render={({ field: { name, onBlur, onChange, value } }) => (
          <MultiTextFormFieldControlledComponent
            disabled={disabled}
            error={!!errorMessage}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            value={value as string[]}
            items={items}
            showMoreIndicator={showMoreIndicator}
          />
        )}
        rules={{
          validate: (v: string[]) => {
            const numberOfEntries = v?.filter(Boolean).length ?? 0;

            if (min?.value != null && numberOfEntries < min.value) {
              return min.message;
            }
            if (max?.value != null && numberOfEntries > max.value) {
              return max.message;
            }
            if (required?.value && numberOfEntries === 0) {
              return required.message;
            }
            return true;
          },
        }}
      />
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

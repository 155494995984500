import { Typography } from "@mui/material";
import { Markdown } from "@pimo/pimo-components";

import { BaseLayoutElementDefinition } from "../layout-element";

export const FORM_LABEL_LAYOUT_ELEMENT_TYPE = "form-label";

export type FormLabelLayoutElementDefinition = BaseLayoutElementDefinition & {
  type: typeof FORM_LABEL_LAYOUT_ELEMENT_TYPE;
  label: string;
  description?: string;
};

export function FormLabelLayoutElement(
  props: FormLabelLayoutElementDefinition
) {
  return (
    <>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 700,
        }}
      >
        {props.label}
      </Typography>
      {props.description && (
        <Typography component="div" sx={{ fontSize: "14px !important", px: 2 }}>
          <Markdown fontSizeRelativeTo="parent">{props.description}</Markdown>
        </Typography>
      )}
    </>
  );
}

import { Box } from "@mui/material";
import type { FC } from "react";

import { getStatusIcon, STATUS_MAPPING } from "../../app/utils";

export const UpdateIcon: FC<{ status?: keyof typeof STATUS_MAPPING }> = ({
  status,
}) => {
  if (!status) {
    return null;
  }

  return (
    <Box
      component="img"
      src={getStatusIcon(status)}
      sx={{ width: 25, height: 25 }}
    />
  );
};

import { TextField } from "@mui/material";

import type {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
  FormFieldValidationDefinition,
} from "../form-field";
import { TEXT_FORM_FIELD_TYPE } from "./types";

export type TextFormFieldDefinition = BaseFormFieldDefinition<
  typeof TEXT_FORM_FIELD_TYPE
> & {
  type: typeof TEXT_FORM_FIELD_TYPE;
  maxLength?: FormFieldValidationDefinition<number>;
  minLength?: FormFieldValidationDefinition<number>;
  multiline?: boolean;
  pattern?: FormFieldValidationDefinition<RegExp>;
  rows?: number;
  maxRows?: number;
  minRows?: number;
};

export function TextFormField({
  disabled,
  errorMessage,
  maxLength,
  minLength,
  multiline,
  name,
  pattern,
  register,
  required,
  rows,
  maxRows,
  minRows,
}: TextFormFieldDefinition & AdditionalFormFieldProps) {
  return (
    <TextField
      {...register(name, { disabled, maxLength, minLength, pattern, required })}
      error={!!errorMessage}
      fullWidth
      helperText={errorMessage}
      multiline={multiline}
      rows={rows}
      maxRows={maxRows}
      minRows={minRows}
    />
  );
}

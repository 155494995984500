import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { Card, CardContent } from "@pimo/pimo-components/src/lib/molecules";
import { Comment } from "in-types";
import { getQuarterAndYear } from "in-utils";
import { ReactNode, useEffect, useState } from "react";

type CommentsCardProps = {
  cardTitle: string;
  comments: Comment[];
  endSlot?: ReactNode;
};

const findOldestCommentId = (comments: Comment[]): string | undefined => {
  if (comments.length === 0) return;

  const oldestComment = comments.reduce((oldest, comment) => {
    const commentYear = parseInt(comment.year, 10);
    const commentQuarter = parseInt(comment.quarter?.slice(1), 10);
    const oldestYear = parseInt(oldest.year, 10);
    const oldestQuarter = parseInt(oldest.quarter?.slice(1), 10);

    if (
      commentYear < oldestYear ||
      (commentYear === oldestYear && commentQuarter < oldestQuarter)
    ) {
      return comment;
    }
    return oldest;
  }, comments[0]);

  return oldestComment.id;
};

export const CommentsCard: PimoReactComponent<CommentsCardProps> = ({
  comments,
  cardTitle,
  endSlot,
}) => {
  const [currentQuarter, currentYear] = getQuarterAndYear();
  const [activeYear, setActiveYear] = useState(currentYear);
  const [activeQuarter, setActiveQuarter] = useState(currentQuarter);
  const [quarterOffset, setQuarterOffset] = useState(0);
  const [currentComment, setCurrentComment] = useState<{
    id?: string;
    content: string;
  }>({
    content: "",
  });
  const [oldestCommentId, setOldestCommentId] = useState<string | undefined>();

  useEffect(() => {
    const [currentQuarter, currentYear] = getQuarterAndYear();
    setActiveQuarter(currentQuarter);
    setActiveYear(currentYear);
  }, []);

  useEffect(() => {
    if (comments && comments.length > 0) {
      const oldestCommentId = findOldestCommentId(comments);
      setOldestCommentId(oldestCommentId);
    }
  }, [comments]);

  useEffect(() => {
    const currentComment = comments?.find(
      (comment) =>
        comment.year === activeYear && comment.quarter === activeQuarter
    ) || { content: "" };

    setCurrentComment(currentComment);
  }, [activeQuarter, activeYear, comments]);

  const handlePrevious = () => {
    const [quarter, year] = getQuarterAndYear(new Date(), quarterOffset - 1);
    setActiveYear(year);
    setActiveQuarter(quarter);
    setQuarterOffset((prev) => prev - 1);
  };

  const handleNext = () => {
    const [quarter, year] = getQuarterAndYear(new Date(), quarterOffset + 1);
    setActiveYear(year);
    setActiveQuarter(quarter);
    setQuarterOffset((prev) => prev + 1);
  };

  const isAtOldestComment = currentComment.id === oldestCommentId;

  return (
    <Card title={cardTitle} rightSlot={endSlot}>
      <CardContent>
        <Box
          sx={{
            fontWeight: "bold",
          }}
        >
          {activeQuarter}/{activeYear}
        </Box>
        <Box
          sx={{
            "& .MuiTextField-root": { m: 1, width: "100%" },
          }}
        >
          <Box
            sx={{
              height: "120px",
              overflowY: "auto",
              margin: "8px 0",
              "& .MuiTypography-root": {
                whiteSpace: "pre-wrap",
              },
            }}
          >
            <Typography variant="body1">{currentComment.content}</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flex: "0 0 58px",
            flexDirection: "row",
            gap: 4,
            justifyContent: "space-between",
          }}
        >
          <Button
            disabled={isAtOldestComment}
            onClick={handlePrevious}
            startIcon={<ArrowBack />}
            type="button"
          >
            See previous comments
          </Button>

          <Button
            disabled={quarterOffset >= 0}
            endIcon={<ArrowForward />}
            onClick={handleNext}
            type="button"
          >
            See next comments
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CommentsCard;

import { Box, Card, Grid, Typography } from "@mui/material";
import { ProgressBar } from "@pimo/pimo-components";
import { OeOverview, UpdateStatus } from "in-types";
import { formatDate, IN_COLORS } from "in-utils";
import type { FC } from "react";

import { UpdateIcon } from "../update-icon/update-icon";
import {
  OEsStatusTableEventNames,
  OEsStatusTableEventPayload,
} from "./oes-status-table";

interface TextCellProps {
  title: string;
  value: number | string;
}

interface ProgressCellProps {
  progress: number;
  total: number;
}

interface LastUpdatedCellProps {
  value: string;
}

const progressBarCustomStyle = {
  border: `1px solid ${IN_COLORS.lightBlue}`,
  marginTop: "9px",
  maxWidth: "160px",
};

const typographyStyle = { lineHeight: "1.8" };

const TextCell: FC<TextCellProps> = ({ title, value }) => (
  <>
    <Typography sx={{ ...typographyStyle, fontWeight: "bold" }}>
      {title}
    </Typography>
    <Typography sx={typographyStyle}>{value}</Typography>
  </>
);

const ProgressCell: FC<ProgressCellProps> = ({ progress, total }) => (
  <>
    <Typography sx={{ ...typographyStyle, fontWeight: "bold" }}>
      {`Update Progress ${progress}/${total}`}
    </Typography>
    <ProgressBar
      progress={(progress / total) * 100}
      progressColor={IN_COLORS.lightBlue}
      progressBarHeight="12px"
      backgroundColor={IN_COLORS.white}
      customStyle={progressBarCustomStyle}
    />
  </>
);

const IconCell: FC<{ status: UpdateStatus; title: string }> = ({
  status,
  title,
}) => (
  <Box sx={{ display: "table-cell" }}>
    <Typography sx={{ ...typographyStyle, fontWeight: "bold" }}>
      {title}
    </Typography>
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <UpdateIcon status={status} />
    </Box>
  </Box>
);

const LastUpdatedCell: FC<LastUpdatedCellProps> = ({ value }) => (
  <>
    <Typography sx={{ ...typographyStyle, fontWeight: "bold" }}>
      Last Updated
    </Typography>
    <Box sx={{ display: "flex" }}>
      <Box
        component="img"
        src="calendar.svg"
        alt="last updated icon"
        sx={{ width: 24, height: 24, marginRight: 1 }}
      />
      <Typography sx={typographyStyle}>{value}</Typography>
    </Box>
  </>
);

export type OEsStatusTableRowProps = {
  oe: OeOverview;
  targetYear?: string;
  fireEvent:
    | ((
        event: OEsStatusTableEventNames,
        payload: OEsStatusTableEventPayload
      ) => void)
    | undefined;
};

const OEsStatusTableRow: FC<OEsStatusTableRowProps> = ({
  oe,
  targetYear,
  fireEvent,
}) => {
  const {
    completionStatus,
    id,
    activeInitiatives,
    lastUpdated,
    oeName,
    oeUpdateStatus,
    totalImpact,
  } = oe;
  const totalImpactFormatted = totalImpact?.toLocaleString("en-US");

  const onOECardClick = (id: string) => {
    fireEvent?.("oes-status-table:oe-click", { id });
  };

  return (
    <Card
      key={id}
      sx={{ borderRadius: "12px", boxShadow: "none", cursor: "pointer" }}
      onClick={() => onOECardClick(id)}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          fontSize: "2px",
          height: "100px",
          padding: "0 16px",
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ fontSize: "2px" }}
        >
          <Grid item xs={0.5}>
            <Typography sx={typographyStyle}>{id}</Typography>
          </Grid>
          <Grid item xs={2}>
            <TextCell title="OE" value={oeName} />
          </Grid>
          <Grid item xs={2}>
            <TextCell
              title="Planned/In Progress Initiatives"
              value={`(${activeInitiatives.planned}/${activeInitiatives.inProgress})`}
            />
          </Grid>
          <Grid item xs={2} sx={{ alignSelf: "flex-start" }}>
            <ProgressCell
              progress={completionStatus?.completed ?? 0}
              total={completionStatus?.total ?? 0}
            />
          </Grid>
          <Grid item xs={2}>
            <TextCell
              title={["Total Impact", targetYear ? `EOY ${targetYear}` : null]
                .filter(Boolean)
                .join(" ")}
              value={`${totalImpactFormatted} €`}
            />
          </Grid>
          <Grid item xs={1.5}>
            <LastUpdatedCell value={formatDate(lastUpdated)} />
          </Grid>
          <Grid item xs={2}>
            <IconCell
              status={oeUpdateStatus}
              title="Quarterly Update Complete"
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default OEsStatusTableRow;

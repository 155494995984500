import { Tune } from "@mui/icons-material";
import {
  Box,
  Button,
  ClickAwayListener,
  Popper,
  useTheme,
} from "@mui/material";
import { SearchBar } from "@pimo/pimo-components";
import { FilterData, FilterOptions } from "in-types";
import React, { useState } from "react";

import { OEOverviewFilterDialog } from "../oe-overview-filter-dialog/oe-overview-filter-dialog.tsx";

export type OverviewTitleCardSearchBarSlotProps = {
  handleSearchQueryChange: (value: string) => void;
  filterData: FilterData;
  filterOptions: FilterOptions;
  handleFilterChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    trigger: string
  ) => void;
  clearAllFilters: () => void;
};

export const OverviewTitleCardSearchBarSlot = ({
  clearAllFilters,
  filterData,
  filterOptions,
  handleFilterChange,
  handleSearchQueryChange,
}: OverviewTitleCardSearchBarSlotProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const clickAwayHandler = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid",
          borderRadius: "12px",
          borderColor: theme.palette.secondary.dark,
          backgroundColor: "#FAFAFA",
          "&:hover": {
            backgroundColor: "#FCFCFC",
          },
        }}
      >
        <SearchBar onChange={handleSearchQueryChange} />
        <Box
          id="STATUS_BUTTON_ID"
          data-testid="STATUS_BUTTON_ID"
          sx={{
            backgroundColor: theme.palette.info.light,
            color: "#122B54",
            marginRight: 1.5,
            cursor: "pointer",
            verticalAlign: "middle",
            borderRadius: "8px",
            p: 0.5,
            height: "30px",
            width: "30px",
          }}
          onClick={(e) => {
            setOpen(true);
            setAnchorEl(e.currentTarget);
          }}
        >
          <Tune sx={{ height: "auto", width: "auto" }} />
        </Box>
      </Box>
      {open && (
        <ClickAwayListener
          onClickAway={clickAwayHandler}
          mouseEvent="onMouseUp"
        >
          <Popper
            data-testid="reportingDate-popover"
            open={open}
            anchorEl={anchorEl}
            sx={{
              backgroundColor: "background.paper",
              borderColor: theme.palette.primary.main,
              boxShadow: "0px 0px 5px grey",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 1,
              maxHeight: "84vh",
              overflow: "auto",
            }}
          >
            <Box>
              <OEOverviewFilterDialog
                onClick={() => {
                  setOpen(false);
                }}
                handleChange={handleFilterChange}
                filterData={filterData}
                filterOptions={filterOptions}
              />
              <Button
                onClick={clearAllFilters}
                sx={{
                  backgroundColor: "#0069d9",
                  height: "56px",
                  width: "300px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#0075d9",
                    borderColor: "#0062cc",
                    boxShadow: "none",
                  },
                }}
              >
                Clear All
              </Button>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
};

import { Tune } from "@mui/icons-material";
import { Box, Button, ClickAwayListener, Popper } from "@mui/material";
import {
  PimoTableEventNames,
  PimoTableEventPayload,
  SearchBar,
} from "@pimo/pimo-components";
import {
  INITIATIVE_PHASES,
  InitiativeFilterData,
  InitiativeFilterOptions,
  InitiativePropertyFilters,
  Phase,
  UpdateStatus,
} from "in-types";
import { useState } from "react";

import { theme } from "../../app/theme";
import { InitiativesStatusTableFilterDialog } from "./initiatives-status-table-filter-dialog";

type InitiativesStatusTableFilterProps = {
  filterData: InitiativeFilterData;
  fireEvent?: (
    eventName: PimoTableEventNames,
    payload: PimoTableEventPayload
  ) => void;
};

export const InitiativesStatusTableFilter = ({
  filterData,
  fireEvent,
}: InitiativesStatusTableFilterProps) => {
  function isChangeEvent(
    event: React.ChangeEvent<HTMLInputElement> | { target: { value: string } }
  ): event is React.ChangeEvent<HTMLInputElement> {
    return !!(event.target as HTMLInputElement).name;
  }
  const clickAwayHandler = () => {
    setOpen(false);
  };
  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement> | { target: { value: string } },
    trigger: keyof InitiativePropertyFilters
  ) => {
    if (Array.isArray(filterData[trigger]) && isChangeEvent(event)) {
      const filterArray: string[] = filterData[trigger] ?? [];
      if (event.target.checked) {
        filterArray.push(event.target.name);
      } else {
        const doFilterData = () =>
          filterArray.filter(
            (uncheckedValue) => uncheckedValue !== event.target.name
          );

        if (trigger === "updateStatusFilter") {
          filterData[trigger] = doFilterData() as UpdateStatus[];
        } else {
          filterData[trigger] = doFilterData() as Phase[];
        }
      }
    } else {
      filterData.yearFilter = event.target.value;
    }

    fireEvent?.("filter", {
      filter: {
        ...filterData,
      },
    });
  };
  const handleSearchChange = (search: string) => {
    fireEvent?.("filter", {
      filter: {
        ...filterData,
        searchQueryFilter: search,
      },
    });
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const clearAllFilters = () => {
    fireEvent?.("filter", {
      filter: {
        searchQueryFilter: "",
        yearFilter: "",
        phaseFilter: [],
        updateStatusFilter: [],
      },
    });
  };

  const filterOptions = {
    phase: [...INITIATIVE_PHASES],
    updateStatus: ["completed", "overdue", "pending"],
  } as InitiativeFilterOptions;

  return (
    <Box
      sx={{
        display: "flex",
        color: "#122B54",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: 2,
        marginLeft: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid",
          borderRadius: "12px",
          borderColor: theme.palette.secondary.dark,
          backgroundColor: "#FAFAFA",
          "&:hover": {
            backgroundColor: "#FCFCFC",
          },
        }}
      >
        <SearchBar onChange={handleSearchChange} />
        <Box
          id={"STATUS_BUTTON_ID"}
          data-testid={"STATUS_BUTTON_ID"}
          sx={{
            backgroundColor: theme.palette.info.light,
            marginRight: 1.5,
            cursor: "pointer",
            verticalAlign: "middle",
            borderRadius: "8px",
            p: 0.5,
            height: "30px",
            width: "30px",
          }}
          onClick={(e) => {
            setOpen(true);
            setAnchorEl(e.currentTarget);
          }}
        >
          <Tune />
        </Box>
      </Box>
      {open && (
        <ClickAwayListener
          onClickAway={clickAwayHandler}
          mouseEvent={"onMouseUp"}
        >
          <Popper
            data-testid={"reportingDate-popover"}
            open={open}
            anchorEl={anchorEl}
            sx={{
              backgroundColor: "background.paper",
              borderColor: theme.palette.primary.main,
              boxShadow: "0px 0px 5px grey",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 1,
              maxHeight: "80vh",
              overflow: "auto",
            }}
          >
            <Box>
              <InitiativesStatusTableFilterDialog
                onClick={() => {
                  setOpen(false);
                }}
                handleChange={(event, trigger) => {
                  handleFilterChange(
                    event,
                    trigger as keyof InitiativePropertyFilters
                  );
                }}
                filterData={filterData}
                filterOptions={filterOptions}
              />
              <Button
                onClick={clearAllFilters}
                sx={{
                  backgroundColor: "#0069d9",
                  height: "56px",
                  width: "300px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#0075d9",
                    borderColor: "#0062cc",
                    boxShadow: "none",
                  },
                }}
              >
                Clear All
              </Button>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
};

import { App, Plugin, View } from "@pimo/pimo-app-builder";

import type { InAppState } from "../app";
import { APP_ROUTES } from "../constants";
import { isUserAdmin } from "../helpers/is-user-admin";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface HomePluginState {}

export class HomePlugin<AppState extends InAppState>
  implements Plugin<AppState, HomePluginState>
{
  onRegister(app: App<AppState>): void {
    const route = app.createRoute({
      path: APP_ROUTES.home,
      view: new View("home", { getLayoutComponent: () => () => null }),
    });

    route.on("load", () => {
      const { isLoggedIn, userProfile } = app.getAppState();

      if (isLoggedIn) {
        if (isUserAdmin(userProfile)) {
          app.navigate(APP_ROUTES.dashboard);
        } else {
          app.navigate(APP_ROUTES.overview);
        }
      } else {
        app.navigate(APP_ROUTES.login);
      }
    });
  }
}

import {
  FormControl,
  FormHelperText,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { Controller, useFormContext } from "react-hook-form";

import type {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
} from "../form-field";
import { DATE_FORM_FIELD_TYPE } from "./types";

export const DATE_FORMAT = "YYYY-MM-DD";

export type DateFormFieldDefinition = BaseFormFieldDefinition<
  typeof DATE_FORM_FIELD_TYPE
> & {
  type: typeof DATE_FORM_FIELD_TYPE;
  disabled?: boolean;
  format?: string;
  value: string;
};

export function DateFormField({
  errorMessage,
  name,
  disabled,
  format = DATE_FORMAT,
}: DateFormFieldDefinition & AdditionalFormFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl error={!!errorMessage} fullWidth>
            <DatePicker
              disabled={disabled}
              InputProps={{ style: { height: "100%" } }}
              renderInput={(params: TextFieldProps) => (
                <TextField {...params} error={!!errorMessage} />
              )}
              inputFormat={format}
              {...field}
              onChange={(date: Dayjs | null) => {
                onChange(date?.format(format) ?? "");
              }}
            />
            {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
          </FormControl>
        </LocalizationProvider>
      )}
    ></Controller>
  );
}

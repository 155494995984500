import { Typography } from "@mui/material";
import { Markdown } from "@pimo/pimo-components";

import type { BaseLayoutElementDefinition } from "../layout-element";

export const SUBHEADLINE_LAYOUT_ELEMENT_TYPE = "subheadline";

export type SubheadlineLayoutElementDefinition = BaseLayoutElementDefinition & {
  type: typeof SUBHEADLINE_LAYOUT_ELEMENT_TYPE;
  label: string;
  description?: string;
};

export function SubheadlineLayoutElement(
  props: SubheadlineLayoutElementDefinition
) {
  return (
    <>
      <Typography
        variant="h6"
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
        }}
      >
        {props.label}
      </Typography>
      {props.description && (
        <Typography sx={{ fontSize: "14px !important" }}>
          <Markdown fontSizeRelativeTo="parent">{props.description}</Markdown>
        </Typography>
      )}
    </>
  );
}

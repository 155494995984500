import { NotificationsPlugin } from "@pimo/notifications-plugin";
import type { App, Plugin, Route, View } from "@pimo/pimo-app-builder";
import {
  TwoColumnGridLayout,
  TwoColumnGridLayoutProps,
} from "@pimo/pimo-components";

import { InAppState } from "../app";
import { APP_ROUTES } from "../constants";

// eslint-disable-next-line
export interface SettingsPluginState {}

export class SettingsPlugin
  implements Plugin<InAppState, SettingsPluginState, "viewname">
{
  route?: Route<"viewname", InAppState>;
  private settingsView?: View<InAppState, TwoColumnGridLayoutProps>;
  private app?: App<InAppState>;

  onRegister(app: App<InAppState>): void {
    this.app = app;

    const entries = [
      {
        link: APP_ROUTES.settingsNotifications,
        title: "Notifications",
        viewname: "notifications",
      },
    ];

    this.settingsView = app.createView({
      name: "Settings",
      layout: new TwoColumnGridLayout({
        spacing: 1,
        enablePageBreak: false,
        workspaceRoute: APP_ROUTES.overview,
        entries,
        title: "Settings",
      }),
    });

    this.buildRoute();

    this.buildNotificationsView();
  }

  private buildRoute() {
    if (!this.app || !this.settingsView) return;

    this.route = this.app.createRoute<"viewname">({
      path: "/settings/:viewname",
      view: this.settingsView,
    });
  }

  private buildNotificationsView() {
    if (!this.settingsView || !this.route) return;

    const notificationsPlugin = this.app?.getPluginByName<
      NotificationsPlugin<InAppState>
    >("NotificationsPlugin");

    notificationsPlugin?.renderNotificationsManager({
      view: this.settingsView,
      route: this.route as Route,
      layoutProps: { viewname: "notifications", xs: 12 },
    });
  }
}
